/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import env from "../../env";
import { SiteLink } from "../../components";
import { usePost } from "../../components";
import { ErrorPopup, Loading } from "../../components";
import { useRouter } from "../../components";
import tileListStyle from "../productTile--slider/productListStyle";
import tileStyle from "../productTile/productTileStyle";
import { Tile } from "../productTile/productTile";
import { useBV } from "../bazzarVoice/useBV";

export function SearchResult(props) {
  const { query } = useRouter();
  const post = usePost();
  useBV(null, { showReview: null });

  const keyword = query.q;
  //console.log(keyword);

  React.useEffect(() => {
    post.send(env.apiBase + "/api/search/search", { keyword: keyword });
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={post.errors} />;
  }

  // console.log(post);
  const data = post.response;

  const products = data.products;
  const productsOn = products.filter(function (obj) {
    return obj.productStatus !== 98;
  });

  const pages = data.pages;
  console.log("pages ----- ", pages);

  if (productsOn.length === 0 && pages.length === 0)
    return (
      <div className='alert alert-danger' role='alert'>
        No result found.
      </div>
    );
  return (
    <React.Fragment>
      {productsOn.length > 0 && (
        <div>
          <h3>Products</h3>
          <ProductList products={productsOn} />
        </div>
      )}
      {pages.length > 0 && (
        <div>
          <h3 style={{ marginTop: "2rem" }}>Related Recipes</h3>
          <PageList pages={pages} />
        </div>
      )}
    </React.Fragment>
  );
}

function ProductList({ products }) {
  return (
    <div css={tileListStyle.products}>
      {products.map((product, index) => (
        <div css={tileListStyle.productCol} key={product.productCode}>
          <Tile product={product} style={tileStyle}></Tile>
        </div>
      ))}
    </div>
  );
}

function PageList({ pages }) {
  return (
    <div className='pageList row'>
      {pages.map((page, index) => (
        <div className='pageListItem col-md-6 col-lg-4' key={index}>
          <SiteLink className='pageListItem__inner' to={page.pageUrl}>
            {page.imageUrl ? (
              <img
                src={"https://cuisinart.com.au" + page.imageUrl}
                alt='page_image'
                className='page_image'
              />
            ) : (
              <img
                src='/assets/logo-black.png'
                alt='page_image'
                className='page_image'
              />
            )}
            <div className='pageListItem__title'>{page.pageTitle}</div>
          </SiteLink>
        </div>
      ))}
    </div>
  );
}
